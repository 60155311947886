import { createApiRef, FetchApi, OAuthApi } from '@backstage/core-plugin-api';

export interface ResolveAccessApi {
  resolveAccess(): Promise<string>;
  proxyRequest(url: string, options?: RequestInit): Promise<Response>;
}

export const resolveAccessApiRef = createApiRef<ResolveAccessApi>({
  id: 'plugin.deployments.proxy',
});

export type Options = {
  githubAuthApi: OAuthApi;
  fetchApi: FetchApi;
};

export class ResolveAccessApiClient implements ResolveAccessApi {
  private readonly githubAuthApi: OAuthApi;
  private readonly fetchApi: FetchApi;

  constructor(options: Options) {
    this.githubAuthApi = options.githubAuthApi;
    this.fetchApi = options.fetchApi;
  }

  async resolveAccess(): Promise<string> {
    const token = await this.githubAuthApi.getAccessToken();
    const encode = Buffer.from(token).toString('base64');
    return encode;
  }

  async proxyRequest(url: string): Promise<Response> {
    const response = await this.fetchApi.fetch(
      url,
      // {
      //   headers: {
      //     Identity: `${await this.resolveAccess()}`,
      //   },
      // }
    );
    return response;
  }
}
