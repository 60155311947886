import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocationRounded';
import ListAlt from '@material-ui/icons/ListAlt';
import TimeLine from '@material-ui/icons/Timeline';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import CreateComponentIcon from '@material-ui/icons/Create';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import FlagIcon from '@mui/icons-material/FlagOutlined';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  SidebarExpandButton,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
  Link,
  SidebarSubmenu,
  SidebarSubmenuItem,
  GroupIcon,
  UserIcon,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SearchIcon from '@material-ui/icons/Search';
import CategoryIcon from '@material-ui/icons/Category';
import { Shortcuts } from '@backstage-community/plugin-shortcuts';
import LayersIcon from '@material-ui/icons/Layers';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { IconComponent } from '@backstage/core-plugin-api';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  return (
    <SidebarPage>
      <Sidebar disableExpandOnHover>
        <SidebarLogo />

        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SidebarSearchModal />
        </SidebarGroup>
        <SidebarDivider />

        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          {/* Global nav, not org-specific */}
          <SidebarItem icon={HomeIcon} to="/" text="Home" />

          <SidebarItem icon={MenuBookIcon} to="/catalog" text="Catalog">
            <SidebarSubmenu>
              <SidebarSubmenuItem
                icon={CategoryIcon}
                to="/catalog?filters%5Bkind%5D=component&filters%5Buser%5D=all"
                title="Components"
              />
              <SidebarSubmenuItem
                icon={ExtensionIcon}
                to="/catalog?filters%5Bkind%5D=api&filters%5Buser%5D=all"
                title="APIs"
              />
              <SidebarSubmenuItem
                icon={GroupIcon}
                title="Groups"
                to="/catalog?filters%5Bkind%5D=group&filters%5Btype%5D=team&filters%5Buser%5D=all"
              />
              <SidebarSubmenuItem
                icon={UserIcon}
                to="/catalog?filters%5Bkind%5D=user&filters%5Buser%5D=all"
                title="Users"
              />
            </SidebarSubmenu>
          </SidebarItem>

          <SidebarItem
            icon={LibraryBooks}
            to="docs"
            text="Docs"
            about="Browse Tech Documentation"
          />

          <SidebarItem icon={LayersIcon} to="explore" text="Explore" />
          <SidebarItem
            icon={AnnouncementIcon}
            to="announcements"
            text="Announcements"
          />
          <SidebarItem icon={StorefrontIcon} to="bazaar" text="Bazaar" />
          <SidebarItem
            icon={CreateComponentIcon}
            to="create"
            text="Create..."
          />
          <SidebarDivider />

          <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
          <SidebarItem icon={TimeLine} to="oe-metrics" text="OE Metrics" />
          <SidebarItem
            icon={ListAlt}
            to="oe-weekly-checklist"
            text="OE Checklist"
          />
          <SidebarItem
            icon={ErrorOutlineIcon}
            to="incident-report"
            text="IR Report"
          />
          <SidebarItem
            icon={RocketLaunchIcon as IconComponent}
            to="deployments"
            text="Deployments"
          />

          <SidebarItem
            icon={FlagIcon as IconComponent}
            to="splitio"
            text="Split.io"
          />

          <SidebarDivider />
          <Shortcuts />
          <SidebarSpace />
        </SidebarGroup>

        <SidebarSpace />
        <SidebarDivider />
        <SidebarGroup
          label="Settings"
          icon={React.createElement(UserSettingsSignInAvatar)}
          to="/settings"
        >
          <SidebarSettings />
        </SidebarGroup>
        <SidebarExpandButton />
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
