import React from 'react';
import type { FieldValidation } from '@rjsf/utils';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText, Input, InputLabel } from '@material-ui/core';

export const ValidateServiceName = ({
  onChange,
  rawErrors,
  required,
  formData,
}: FieldExtensionComponentProps<string>) => {
  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <InputLabel htmlFor="validateName">Name</InputLabel>
      <Input
        id="validateName"
        aria-describedby="entityName"
        onChange={e => onChange(e.target?.value)}
      />
      <FormHelperText id="entityName">
        Use only letters, numbers, hyphens and underscores
      </FormHelperText>
    </FormControl>
  );
};

export const validateServiceNameValidation = (
  value: string,
  validation: FieldValidation,
) => {
  if (!/^[a-z0-9-_]+$/g.test(value)) {
    validation.addError(
      `Only use letters, numbers, hyphen ("-") and underscore ("_").`,
    );
  } else if (!['-app', '-bff', '-service'].some(char => value.endsWith(char))) {
    validation.addError(
      `Service name should end with -app, -bff or, -service.`,
    );
  }
};
