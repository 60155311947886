import React from 'react';
import type { FieldValidation } from '@rjsf/utils';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText, Input, InputLabel } from '@material-ui/core';

export const ValidateServiceEmailIds = ({
  onChange,
  rawErrors,
  required,
  formData,
}: FieldExtensionComponentProps<string>) => {
  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <InputLabel htmlFor="validateEmailIds">
        Comma separated email ids of users
      </InputLabel>
      <Input
        id="validateEmailIds"
        aria-describedby="entityName"
        onChange={e => onChange(e.target?.value)}
      />
      <FormHelperText id="entityName">
        Use only official email id with @hingehealth.com domain
      </FormHelperText>
    </FormControl>
  );
};

export const validateServiceEmailIdsValidation = (
  value: string,
  validation: FieldValidation,
) => {
  const emailIds = value.split(',').map(email => email.trim());

  if (emailIds.length !== new Set(emailIds).size) {
    validation.addError(
      'Duplicate email ids found. Please use only unique email ids.',
    );
    return;
  }

  emailIds.forEach(email => {
    if (!/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/g.test(email)) {
      validation.addError(`${email} - Please use a valid email id.`);
    }
    if (!email.endsWith('@hingehealth.com')) {
      validation.addError(
        `${email} - Please use a email id with @hingehealth.com domain`,
      );
    }
  });
};
