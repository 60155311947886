import {
  ClockConfig,
  HomePageToolkit,
  HomePageStarredEntities,
  HeaderWorldClock,
} from '@backstage/plugin-home';
import { Content, Header, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { Divider, Grid, Box, makeStyles } from '@material-ui/core';
import React from 'react';
import HHLogoFull from './hh-logo-small.png';
import {
  toolbox as employee_tools,
  dev_tools as developer_tools,
  science_tools,
} from './toolkit/Toolkit';
// import { useAnalytics } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { ComponentAccordion } from '@backstage/plugin-home';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import {
  UserSettingsMenu,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import {
  HomePageRequestedReviewsCard,
  HomePageYourOpenPullRequestsCard,
} from '@roadiehq/backstage-plugin-github-pull-requests';
import { NewAnnouncementBanner } from '@k-phoen/backstage-plugin-announcements';
import { AnnouncementsCard } from '@k-phoen/backstage-plugin-announcements';
import { BazaarOverviewCard } from '@backstage-community/plugin-bazaar';

const clockConfigs: ClockConfig[] = [
  {
    label: 'PST',
    timeZone: 'America/Los_Angeles',
  },
  {
    label: 'EST',
    timeZone: 'America/New_York',
  },
  {
    label: 'IST',
    timeZone: 'Asia/Kolkata',
  },
];

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
};

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: 'auto',
  },
  png: {
    width: 'auto',
    height: 150,
  },
  path: {
    fill: '#7df3e1',
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  // const analytics = useAnalytics();
  // analytics.captureEvent("deploy", "hello analytics world!")
  const identityApi = useApi(identityApiRef);
  const { value: profile } = useAsync(() => identityApi.getProfileInfo());
  // const {value: user} = useAsync(() => identityApi.getBackstageIdentity());
  // const userOwnedReferences = user?.ownershipEntityRefs
  // let query = 'is:pull-request ';
  // for (const ref of userOwnedReferences ?? []) {
  //   // Only include groups that are not "engineering" to look at PRs
  //   if (ref.includes('group') && !ref.includes('engineering')) {
  //     query = query.concat(`team:hinge-health/${ref.split('/')[1]} `)
  //   }
  // }

  // const pullReqQuery = query;

  const ExpandedComponentAccordion = (props: any) => (
    <ComponentAccordion expanded {...props} />
  );

  return (
    <Page themeId="home">
      <Header
        title="RnD Mission Control"
        subtitle="Beta version"
        pageTitleOverride="Home"
      >
        <HeaderWorldClock
          clockConfigs={clockConfigs}
          customTimeFormat={timeFormat}
        />
        <UserSettingsSignInAvatar size={50} />
        <UserSettingsMenu />
      </Header>
      <Content>
        <NewAnnouncementBanner />
        <Grid container justifyContent="center" spacing={6}>
          <Grid item justifyContent="center">
            <img alt="Hinge Health" className={classes.png} src={HHLogoFull} />
            <Box sx={{ fontSize: '25px', fontWeight: 'bold' }}>
              Welcome, {profile?.displayName}!
            </Box>
          </Grid>
          <Grid container item xs={12} alignItems="center">
            <HomePageSearchBar
              classes={{ root: classes.searchBar }}
              placeholder="Search across software components, documentation etc."
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          spacing={6}
          alignItems="flex-start"
        >
          <Grid item direction="column" md={5}>
            <HomePageToolkit
              tools={developer_tools
                .concat(employee_tools)
                .concat(science_tools)}
              title="Developer Toolkit"
              Renderer={ExpandedComponentAccordion}
            />
            <HomePageStarredEntities
              title="Your Favorites"
              Renderer={ExpandedComponentAccordion}
            />
          </Grid>
          <Grid item direction="column" md={5}>
            <AnnouncementsCard max={3} title="📣 Announcements" />
            <BazaarOverviewCard title="Projects" order="latest" />
            <HomePageRequestedReviewsCard />
            <HomePageYourOpenPullRequestsCard />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" spacing={6}>
          <Box sx={{ fontSize: '15px', fontWeight: 'bold' }}>
            Built with ❤️ on{' '}
            <a target="_blank" href="https://backstage.io/">
              Backstage
            </a>
          </Box>
        </Grid>
      </Content>
      <Divider />
    </Page>
  );
};
