import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Tool } from '@backstage/plugin-home';
import { useTheme } from '@material-ui/core/styles';

/* Icons */
import Guru from './icons/guru.svg';
import CloudSearch from './icons/cloudsearch.png';
import AWS from './icons/aws.png';
import Swarmia from './icons/swarmia.png';
import Github from './icons/github.png';
import Calendar from './icons/calendar.png';
import DataBricks from './icons/databricks.png';
import DataDogDark from './icons/datadog-dark.png';
import DataDogLight from './icons/datadog-light.png';
import Gmail from './icons/gmail.png';
import LinkedInLearning from './icons/linkedin.png';
import Meet from './icons/meet.png';
import MixPanel from './icons/mixpanel.png';
import Mode from './icons/mode.png';
import Okta from './icons/okta.png';
import PagerDuty from './icons/pagerduty.png';
import Sentry from './icons/sentry.png';
import Slack from './icons/slack.png';
import SonarQube from './icons/sonarqube.png';
import SumoLogic from './icons/sumologic.png';
import Tableau from './icons/tableau.svg';
import ITSupport from './icons/freshdesk.png';
import Mend from './icons/mend.png';
import Lucid from './icons/lucid.png';

import { lightTheme } from '@backstage/theme';
import { apertureTheme } from '../../../themes/aperture';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
});

const GuruIcon = () => {
  const classes = useStyles();
  return <img alt="Guru" className={classes.svg} src={Guru} />;
};
const CloudSearchIcon = () => {
  const classes = useStyles();
  return (
    <img alt="Google CloudSearch" className={classes.svg} src={CloudSearch} />
  );
};
const AwsIcon = () => {
  const classes = useStyles();
  return <img alt="AWS Console" className={classes.svg} src={AWS} />;
};
const SwarmiaIcon = () => {
  const classes = useStyles();
  return <img alt="Swarmia" className={classes.svg} src={Swarmia} />;
};
const GithubIcon = () => {
  const classes = useStyles();
  return <img alt="Github" className={classes.svg} src={Github} />;
};
const CalendarIcon = () => {
  const classes = useStyles();
  return <img alt="Calendar" className={classes.svg} src={Calendar} />;
};
const DataBricksIcon = () => {
  const classes = useStyles();
  return <img alt="DataBricks" className={classes.svg} src={DataBricks} />;
};
const DataDogIcon = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <img
      alt="DataDog"
      className={classes.svg}
      src={
        theme.palette === lightTheme.palette ||
        theme.palette === apertureTheme.palette
          ? DataDogLight
          : DataDogDark
      }
    />
  );
};
const GmailIcon = () => {
  const classes = useStyles();
  return <img alt="Mail" className={classes.svg} src={Gmail} />;
};
const LinkedInLearningIcon = () => {
  const classes = useStyles();
  return (
    <img
      alt="LinkedIn Learning"
      className={classes.svg}
      src={LinkedInLearning}
    />
  );
};
const MeetIcon = () => {
  const classes = useStyles();
  return <img alt="Google Meet" className={classes.svg} src={Meet} />;
};
const MixPanelIcon = () => {
  const classes = useStyles();
  return <img alt="MixPanel" className={classes.svg} src={MixPanel} />;
};
const ModeIcon = () => {
  const classes = useStyles();
  return <img alt="Mode Analytics" className={classes.svg} src={Mode} />;
};
const OktaIcon = () => {
  const classes = useStyles();
  return <img alt="Okta" className={classes.svg} src={Okta} />;
};
const PagerDutyIcon = () => {
  const classes = useStyles();
  return <img alt="PagerDuty" className={classes.svg} src={PagerDuty} />;
};
const SentryIcon = () => {
  const classes = useStyles();
  return <img alt="Sentry" className={classes.svg} src={Sentry} />;
};
const SlackIcon = () => {
  const classes = useStyles();
  return <img alt="Slack Web App" className={classes.svg} src={Slack} />;
};
const SonarQubeIcon = () => {
  const classes = useStyles();
  return <img alt="SonarQube" className={classes.svg} src={SonarQube} />;
};
const SumoLogicIcon = () => {
  const classes = useStyles();
  return <img alt="SumoLogic Logs" className={classes.svg} src={SumoLogic} />;
};
const TableauIcon = () => {
  const classes = useStyles();
  return <img alt="Tableau" className={classes.svg} src={Tableau} />;
};
const ITSupportIcon = () => {
  const classes = useStyles();
  return <img alt="IT Support" className={classes.svg} src={ITSupport} />;
};
const MendIcon = () => {
  const classes = useStyles();
  return <img alt="Mend" className={classes.svg} src={Mend} />;
};
const LucidIcon = () => {
  const classes = useStyles();
  return <img alt="Lucid" className={classes.svg} src={Lucid} />;
};

export const dev_tools: Tool[] = [
  {
    url: 'https://app.getguru.com/',
    label: 'Guru',
    icon: <GuruIcon />,
  },
  {
    url: 'https://cloudsearch.google.com/cloudsearch',
    label: 'Cloud Search',
    icon: <CloudSearchIcon />,
  },
  {
    url: 'https://hingehealthsso.awsapps.com/start#/',
    label: 'AWS',
    icon: <AwsIcon />,
  },
  {
    url: 'https://app.swarmia.com/',
    label: 'Swarmia',
    icon: <SwarmiaIcon />,
  },
  {
    url: 'https://github.com/orgs/hinge-health/dashboard',
    label: 'Github',
    icon: <GithubIcon />,
  },
  {
    url: 'https://app.datadoghq.com/apm/home?env=prod',
    label: 'DataDog',
    icon: <DataDogIcon />,
  },
  {
    url: 'https://hingehealth.pagerduty.com/',
    label: 'PagerDuty',
    icon: <PagerDutyIcon />,
  },
  {
    url: 'https://hingehealth.sentry.io/',
    label: 'Sentry',
    icon: <SentryIcon />,
  },
  {
    url: 'https://sonar.it.hingehealth.net/',
    label: 'SonarQube',
    icon: <SonarQubeIcon />,
  },
  {
    url: 'https://service.us2.sumologic.com/ui/#/home',
    label: 'SumoLogic',
    icon: <SumoLogicIcon />,
  },
  {
    url: 'https://app.whitesourcesoftware.com/Wss/WSS.html',
    label: 'Mend',
    icon: <MendIcon />,
  },
  {
    url: 'https://lucid.app/documents#/dashboard',
    label: 'Lucid',
    icon: <LucidIcon />,
  },
];

export const science_tools: Tool[] = [
  {
    url: 'https://hingehealth.cloud.databricks.com/',
    label: 'DataBricks',
    icon: <DataBricksIcon />,
  },
  {
    url: 'https://mixpanel.com/project/1719061/view/52631/app/boards',
    label: 'MixPanel',
    icon: <MixPanelIcon />,
  },
  {
    url: 'https://app.mode.com/home/hinge_health/discover',
    label: 'Mode Analytics',
    icon: <ModeIcon />,
  },
  {
    url: 'https://tableau.analytics.hingehealth.com/',
    label: 'Tableau',
    icon: <TableauIcon />,
  },
];

export const toolbox: Tool[] = [
  {
    url: 'https://calendar.google.com/calendar',
    label: 'Calendar',
    icon: <CalendarIcon />,
  },
  {
    url: 'https://mail.google.com/',
    label: 'Mail',
    icon: <GmailIcon />,
  },
  {
    url: 'https://www.linkedin.com/learning/browse/urn:li:enterpriseAccount:141760722',
    label: 'LinkedIn Learning',
    icon: <LinkedInLearningIcon />,
  },
  {
    url: 'https://meet.google.com/',
    label: 'Meet',
    icon: <MeetIcon />,
  },
  {
    url: 'https://hingehealth-wf.okta.com/',
    label: 'Okta',
    icon: <OktaIcon />,
  },
  {
    url: 'https://app.slack.com/client/T02SRN0U1/C02SRN0UB',
    label: 'Slack Web',
    icon: <SlackIcon />,
  },
  {
    url: 'https://hingehealth-people.freshdesk.com/support/home',
    label: 'IT Support',
    icon: <ITSupportIcon />,
  },
];
