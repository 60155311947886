import {
  GroupsExplorerContent,
  ToolExplorerContent,
  ExploreLayout,
} from '@backstage-community/plugin-explore';

import React from 'react';

export const ExplorePage = () => {
  return (
    <ExploreLayout
      title="Explore the HingeHealth ecosystem"
      subtitle="Browse our ecosystem"
    >
      <ExploreLayout.Route path="lnd" title="Learning and Development">
        <ToolExplorerContent title="Resources" />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="groups" title="Groups">
        <GroupsExplorerContent />
      </ExploreLayout.Route>
    </ExploreLayout>
  );
};

export const explorePage = <ExplorePage />;
