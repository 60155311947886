import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
  CatalogTable,
} from '@backstage/plugin-catalog';
import { catalogImportPlugin } from '@backstage/plugin-catalog-import';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';

import {
  configApiRef,
  githubAuthApiRef,
  oktaAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { SignInPage } from '@backstage/core-components';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { apertureTheme } from './themes/aperture';
// import { darkTheme, lightTheme } from '@backstage/theme';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import * as plugins from './plugins';
import { ExplorePage } from '@backstage-community/plugin-explore';
import { explorePage } from './components/explore/ExplorePage';
import { AnnouncementsPage } from '@k-phoen/backstage-plugin-announcements';
import { BazaarPage } from '@backstage-community/plugin-bazaar';

// packages/app/src/App.tsx

import { CatalogImportPage } from '@backstage/plugin-catalog-import';
import {
  ValidateServiceJIRATicketFieldExtension,
  ValidateServiceNameFieldExtension,
  ValidateServiceEmailIdsExtension,
} from './scaffolder/ValidateServiceDetails';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { ScaffolderPage } from '@backstage/plugin-scaffolder';
import { SloPage } from '@hingehealth/plugin-slo';
import { OEMetricsPage } from '@hinge-health/plugin-oe-metrics';
import { OeWeeklyChecklistPage } from '@hinge-health/plugin-oe-weekly-checklist';
import { IncidentReportPage } from '@hinge-health/plugin-incident-report';
import { DeploymentsPage } from '@hinge-health/backstage-plugin-deployments';
import { SplitioPage } from '@hinge-health/backstage-plugin-splitio';

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  components: {
    SignInPage: props => {
      const configApi = useApi(configApiRef);
      if (configApi.getString('auth.environment') === 'production') {
        return (
          <SignInPage
            {...props}
            provider={{
              id: 'okta-auth-provider',
              title: 'Okta',
              message: 'Sign In using Okta',
              apiRef: oktaAuthApiRef,
            }}
          />
        );
      }
      return (
        <SignInPage
          {...props}
          provider={{
            id: 'github-auth-provider',
            title: 'Github',
            message: 'Sign In using Github',
            apiRef: githubAuthApiRef,
          }}
        />
      );
    },
  },
  themes: [
    {
      id: 'aperture',
      title: 'Blue Aperture',
      variant: 'light',
      Provider: ({ children }) => (
        <ThemeProvider theme={apertureTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
    // {
    //   id: 'light',
    //   title: 'Light theme',
    //   variant: 'light',
    //   Provider: ({ children }) => (
    //     <ThemeProvider theme={lightTheme}>
    //       <CssBaseline>{children}</CssBaseline>
    //     </ThemeProvider>
    //   ),
    // },{
    //   id: 'dark',
    //   title: 'Dark theme',
    //   variant: 'dark',
    //   Provider: ({ children }) => (
    //     <ThemeProvider theme={darkTheme}>
    //       <CssBaseline>{children}</CssBaseline>
    //     </ThemeProvider>
    //   ),
    // }
  ],
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    // bind(scaffolderPlugin.externalRoutes, {
    //   registerComponent: catalogImportPlugin.routes.importPage,
    // });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const columns = [
  CatalogTable.columns.createNameColumn(),
  CatalogTable.columns.createOwnerColumn(),
  CatalogTable.columns.createSpecTypeColumn(),
  CatalogTable.columns.createTagsColumn(),
  CatalogTable.columns.createMetadataDescriptionColumn(),
];

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route
      path="/catalog"
      element={
        <CatalogIndexPage
          columns={columns}
          tableOptions={{
            search: true,
            paging: true,
            pageSize: 20,
            actionsColumnIndex: 0,
            padding: 'dense',
            showTextRowsSelected: true,
            columnResizable: false,
            sorting: true,
            draggable: true,
            toolbar: true,
            tableLayout: 'auto',
            rowStyle: {
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            },
            columnsButton: true,
          }}
        />
      }
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/catalog-import" element={<CatalogImportPage />} />;
    <Route
      path="/create"
      element={
        <ScaffolderPage
          groups={[
            {
              title: 'Recommended',
              filter: entity =>
                entity?.metadata?.tags?.includes('recommended') ?? false,
            },
          ]}
        />
      }
    >
      <ScaffolderFieldExtensions>
        <ValidateServiceNameFieldExtension />
        <ValidateServiceJIRATicketFieldExtension />
        <ValidateServiceEmailIdsExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={650} />}
    />
    <Route path="/explore" element={<ExplorePage />}>
      {explorePage}
    </Route>
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            maxDepth: 1,
            showFilters: true,
            selectedKinds: ['component', 'domain', 'system', 'api'],
            selectedRelations: [
              RELATION_CONSUMES_API,
              RELATION_API_CONSUMED_BY,
              RELATION_PROVIDES_API,
              RELATION_API_PROVIDED_BY,
              RELATION_HAS_PART,
              RELATION_PART_OF,
              RELATION_DEPENDS_ON,
              RELATION_DEPENDENCY_OF,
            ],
          }}
        />
      }
    />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route
      path="/create"
      element={
        <ScaffolderPage
          groups={[
            {
              title: 'Recommended',
              filter: entity =>
                entity?.metadata?.tags?.includes('recommended') ?? false,
            },
          ]}
        />
      }
    />
    <Route path="/slo" element={<SloPage />} />
    <Route path="/oe-metrics" element={<OEMetricsPage />} />
    <Route path="/oe-weekly-checklist" element={<OeWeeklyChecklistPage />} />
    <Route path="/incident-report" element={<IncidentReportPage />} />
    <Route path="bazaar" element={<BazaarPage />} />
    <Route path="/deployments" element={<DeploymentsPage />} />
    <Route path="/splitio" element={<SplitioPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
