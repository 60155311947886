import {
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const entityOverviewPlugin = createPlugin({
  id: 'entity-overview',
  routes: {
    root: rootRouteRef,
  },
});

export const EntityAboutCard = entityOverviewPlugin.provide(
  createComponentExtension({
    name: 'EntityAboutCard',
    component: {
      lazy: () => import('./components/AboutCard').then(m => m.AboutCard),
    },
  }),
);
