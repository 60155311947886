import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const oeWeeklyChecklistPlugin = createPlugin({
  id: 'oe-weekly-checklist',
  routes: {
    root: rootRouteRef,
  },
});

export const OeWeeklyChecklistPage = oeWeeklyChecklistPlugin.provide(
  createRoutableExtension({
    name: 'OeWeeklyChecklistPage',
    component: () =>
      import('./components/Checklist').then(m => m.ChecklistComponent),
    mountPoint: rootRouteRef,
  }),
);
