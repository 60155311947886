import React from 'react';
import type { FieldValidation } from '@rjsf/utils';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText, Input, InputLabel } from '@material-ui/core';

export const ValidateServiceJIRATicket = ({
  onChange,
  rawErrors,
  required,
  formData,
}: FieldExtensionComponentProps<string>) => {
  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <InputLabel htmlFor="validateJIRA">JIRA Ticket URL</InputLabel>
      <Input
        id="validateJIRA"
        aria-describedby="entityName"
        onChange={e => onChange(e.target?.value)}
      />
      <FormHelperText id="entityName">
        https://hingehealth.atlassian.net/browse/JIRA-001
      </FormHelperText>
    </FormControl>
  );
};

export const validateServiceJIRATicketValidation = (
  value: string,
  validation: FieldValidation,
) => {
  // Check if the URL starts with "https://" or "http://"
  if (!/^https?:\/\//i.test(value)) {
    validation.addError(`Invalid URL.`);
    return;
  }

  // Check if the URL contains the Hinge Health Jira hostname
  if (!/hingehealth\.atlassian\.net\/browse\//i.test(value)) {
    validation.addError(`Invalid URL.`);
    return;
  }

  // Extract the ticket number from the URL
  const ticketRegex = /\/browse\/([A-Za-z]+-\d+)/i;
  const matches = value.match(ticketRegex);
  if (!matches || matches.length < 2) {
    validation.addError(`Invalid JIRA Ticket.`);
    return;
  }
};
