import {
  createPlugin,
  createRoutableExtension,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { createApiFactory, githubAuthApiRef } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { ResolveAccessApiClient, resolveAccessApiRef } from './api';

export const deploymentsPlugin = createPlugin({
  id: 'deployments',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: resolveAccessApiRef,
      deps: {
        githubAuthApi: githubAuthApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ githubAuthApi, fetchApi }) =>
        new ResolveAccessApiClient({ githubAuthApi, fetchApi }),
    }),
  ],
});

export const DeploymentsPage = deploymentsPlugin.provide(
  createRoutableExtension({
    name: 'DeploymentsPage',
    component: () =>
      import('./components/DeploymentComponent').then(
        m => m.DeploymentComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);
