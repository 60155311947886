import React from 'react';
import { makeStyles } from '@material-ui/core';
import HHIcon from '../home/HHIcon.png';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return <img alt="" className={classes.svg} src={HHIcon} />;
};

export default LogoIcon;
