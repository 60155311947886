import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  ValidateServiceJIRATicket,
  validateServiceJIRATicketValidation,
} from './validateServiceJIRATicketExtension';
import {
  ValidateServiceName,
  validateServiceNameValidation,
} from './validateServiceNameExtension';
import {
  ValidateServiceEmailIds,
  validateServiceEmailIdsValidation,
} from './validateServiceEmailIdsExtension';

export const ValidateServiceNameFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'ValidateServiceName',
    component: ValidateServiceName,
    validation: validateServiceNameValidation,
  }),
);

export const ValidateServiceJIRATicketFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'ValidateServiceJIRATicket',
    component: ValidateServiceJIRATicket,
    validation: validateServiceJIRATicketValidation,
  }),
);

export const ValidateServiceEmailIdsExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'ValidateServiceEmailIds',
    component: ValidateServiceEmailIds,
    validation: validateServiceEmailIdsValidation,
  }),
);
