import { createApiRef, FetchApi } from '@backstage/core-plugin-api';

export interface ResolveAccessApi {
  proxyRequest(url: string, options?: RequestInit): Promise<Response>;
}

export const resolveAccessApiRef = createApiRef<ResolveAccessApi>({
  id: 'plugin.splitio',
});

export type Options = {
  fetchApi: FetchApi;
};

export class ResolveAccessApiClient implements ResolveAccessApi {
  private readonly fetchApi: FetchApi;

  constructor(options: Options) {
    this.fetchApi = options.fetchApi;
  }

  async proxyRequest(url: string): Promise<Response> {
    const response = await this.fetchApi.fetch(url);
    return response;
  }
}
